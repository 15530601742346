import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'fc-core-modal-opener',
  template: '',
  standalone: true,
})
export class CoreModalOpenerComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const dialog = this.dialog.open(this.route.snapshot.data.dialog, {
      data: {
        id: this.route.parent.snapshot.params['id'],
        data: this.route.snapshot.data,
        params: this.route.snapshot.params,
      },
      panelClass: this.route.snapshot.data.confirm
        ? 'confirm-dialog'
        : 'full-screen-modal',
      autoFocus: false,
      maxWidth: '100%',
      disableClose: this.route.snapshot.data.confirm,
    });

    dialog
      .afterClosed()
      .pipe(take(1))
      .subscribe(() =>
        this.router.navigate([{ outlets: { modal: null } }], {
          relativeTo: this.route.parent,
        }),
      );
  }
}
